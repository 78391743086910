import React, { useEffect, useState } from 'react';

import { loadFonts } from './font';
import { NavigationContainer } from '@react-navigation/native';
import { Provider } from 'react-redux';
import Navigator from './app/navigation/Navigator';
import store from './app/redux';
import Toast from 'react-native-toast-message';
import {StyleSheet, View, Text } from 'react-native';
import {FontAwesome5} from '@expo/vector-icons';


export default function App() {


  const [isReady, setIsReady] = useState(false);

  const loadAppResources = async () => {
    try {
      //await SplashScreen.preventAutoHideAsync();
      await loadFonts();
    } catch (e) {
      console.error(e);
    } finally {
      setIsReady(true);
      //await SplashScreen.hideAsync();
    }
  };

  useEffect(() => {
    loadAppResources();
  }, []);

  if (!isReady) {
    return null;
  }

  const getActiveRouteName = (state) => {
    const route = state.routes[state.index];
    if (route.state) {
      return getActiveRouteName(route.state);
    }
    return route.name;
  };

  const toastConfig = {
    success: ({ text1, text2, ...rest }) => (
      <View style={[styles.toast, styles.success]}>
        <FontAwesome5 name="check-circle" size={36} color="#28a745" />
        <View style={styles.textContainer}>
          <Text style={styles.text1}>{text1}</Text>
          {text2 ? <Text style={styles.text2}>{text2}</Text> : null}
        </View>
      </View>
    ),
    error: ({ text1, text2, ...rest }) => (
      <View style={[styles.toast, styles.error]}>
        <FontAwesome5 name="times-circle" size={36} color="#dc3545" />
        <View style={styles.textContainer}>
          <Text style={styles.text1}>{text1}</Text>
          {text2 ? <Text style={styles.text2}>{text2}</Text> : null}
        </View>
      </View>
    ),
  };


  return (
    <>
      <Provider store={store}>
        <NavigationContainer
         
        >
          <Navigator/>
        </NavigationContainer>
      </Provider>
      
      <Toast config={toastConfig} />
    </>
  );
}

const styles = StyleSheet.create({
  toast: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderRadius: 5,
    width: '80%',
    minHeight: 120,
  },
  success: {
    backgroundColor: '#eee',
  },
  error: {
    backgroundColor: '#eee',
  },
  textContainer: {
    flex: 1,
    paddingLeft: 10,
  },
  text1: {
    fontSize: 22,
    color: '#000',
    fontWeight: 'bold',
    fontFamily: 'Poppins-Bold',
  },
  text2: {
    fontSize: 18,
    color: '#000',
    fontFamily: 'Poppins',
  },
});
