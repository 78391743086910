/**
 * ViewDeposit.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React, { useEffect } from 'react';
import { ScrollView, View, Text, StyleSheet, Dimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import colors from '../../strings/colors';
import Loader from '../../components/common/Loader';
import { depositActions } from '../../redux/depositSlice';
import { useGetListingImagesQuery, useUpdateCompanyDepositMutation } from '../../redux/api';
import { EXPO_PUBLIC_API_ENDPOINT } from '../../config/app.conf';
import moment from 'moment';
import { toCurrency } from '../../utils/app_utils';
import RoundedButton from '../../components/common/RoundedButton';
import ImageSlider from '../../components/common/ImageSlider';
import { NEW_DEPOSIT } from '../../config/constants/nav-routes.const';
import { useNavigation } from '@react-navigation/native';
import Toast from 'react-native-toast-message';
import { PENDING } from '../../config/constants/approval-statues.const';

const screenWidth = Dimensions.get('window').width;

const ViewDeposit = () => {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const deposit = useSelector(state => state.deposit);
    const user = useSelector(state => state.user);
    const { data: apiImages, isLoading: apiLoading, isSuccess } = useGetListingImagesQuery(deposit._id===''?null:deposit._id);

    const [updateDeposit, updateDepositResult] = useUpdateCompanyDepositMutation();

    useEffect(() => {
        if (isSuccess) {
            dispatch(depositActions.clearImages());
            apiImages.forEach(image => {
                dispatch(depositActions.setImage(`${EXPO_PUBLIC_API_ENDPOINT}/stream/${image.filename}`));
            });
        }
    }, [apiLoading]);

    useEffect(() => {
        if (updateDepositResult.isSuccess) {
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Deposit approved successfully',
            });
            dispatch(depositActions.resetDeposit());
            navigation.goBack();
        }
    }, [updateDepositResult.isLoading]);

    const fromNow = moment(deposit.created_at).fromNow();

    if (apiLoading || updateDepositResult.isLoading) {
        return <Loader message="Loading Deposit Details..." />;
    }

    return (
        <ScrollView style={styles.container}>
            <ImageSlider images={deposit.images} />
            <View style={styles.details}>
                <Text style={styles.title}>Deposit Details</Text>
                <Text style={styles.sectionTitle}>Information</Text>
                <Text style={styles.info}>Source: {deposit.cash_account?.name}</Text>
                <Text style={styles.info}>Bank Account: {deposit.bank_account?.name}</Text>
                <Text style={styles.info}>Amount: {toCurrency(deposit.deposit_amount)}</Text>
                
                <Text style={styles.info}>Status: {deposit.status}</Text>
                <Text style={styles.sectionTitle}>Deposited By: {deposit.created_by?.username}</Text>
                <Text style={styles.info}>Created: {`${fromNow} (${new Date(deposit.created_at).toLocaleDateString()})`}</Text>
                {deposit.status === PENDING && (
                    <>
                        <RoundedButton
                            title="Edit Deposit"
                            onPress={() => {
                                navigation.navigate(NEW_DEPOSIT);
                            }}
                            style={styles.button}
                        />
                        <RoundedButton
                            title="Approve"
                            backgroundColor={colors.light_green}
                            onPress={() => {
                                updateDeposit({ _id: deposit._id, status: 'approved', approvedBy: user._id });
                            }}
                            style={styles.button}
                        />
                    </>
                )}
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: colors.white,
    },
    details: {
        padding: 20,
        backgroundColor: '#eee',
        borderRadius: 10,
        marginVertical: 10,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    sectionTitle: {
        fontSize: 18,
        color: colors.dark_gray,
        marginVertical: 5,
    },
    info: {
        fontSize: 16,
        marginBottom: 5,
    },
    button: {
        marginTop: 20,
        alignSelf: 'center',
    }
});

export default ViewDeposit;
