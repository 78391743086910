import React, { useState, useEffect, useCallback } from 'react';
import { View, FlatList, StyleSheet, Dimensions, Text, Switch } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import {
    useGetCompanyExpensesQuery,
    useGetExpenseCategoriesQuery,
    useGetExpenseAccountsQuery,
    useUpdateCompanyExpenseMutation
} from '../../redux/api';
import Loader from '../../components/common/Loader';
import BillItem from './BillItem';
import StayDropDown from '../../components/common/StayDropDown';
import RoundedButton from '../../components/common/RoundedButton';
import { NEW_BILL, VIEW_BILL } from '../../config/constants/nav-routes.const';
import { billActions } from '../../redux/billSlice';
import { ADMIN, SUPERVISOR } from '../../config/constants/retail-role-codes.const';
import { DATE_RANGE_FILTERS, TODAY } from '../../config/enums/date-range-filters.enum';
import { getDateRange, toCurrency } from '../../utils/app_utils';
import Toast from 'react-native-toast-message';
import { PieChart } from 'react-native-chart-kit';

const Bills = () => {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const company = useSelector(state => state.company);
    const employee = useSelector(state => state.employee);
    const employeeRoleCode = employee.role.code;

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState(TODAY);
    const [chartData, setChartData] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [showFilters, setShowFilters] = useState(false); // State to manage the visibility of filters

    const { data: bills, isLoading, refetch, isFetching } = useGetCompanyExpensesQuery({
        companyId: company._id,
        categoryId: selectedCategory,
        ...getDateRange(selectedDateRange),
    });

    const { data: categories, isLoading: categoriesLoading } = useGetExpenseAccountsQuery(company._id);
    const [updateBill, deleteBillResults] = useUpdateCompanyExpenseMutation();

    const CREATE_AND_FILTER = [ADMIN, SUPERVISOR];

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    useEffect(() => {
        refetch();
    }, [selectedCategory, selectedDateRange, refetch]);

    useEffect(() => {
        if (bills && categories) {
            const data = categories.map(category => {
                const categoryBills = bills.filter(bill => bill.expense_category._id === category._id);
                const totalAmount = categoryBills.reduce((sum, bill) => sum + bill.amount, 0);
                const quantity = categoryBills.length;
                return {
                    name: `${category.name}`,
                    amount: totalAmount,
                    color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                    legendFontColor: '#7F7F7F',
                    legendFontSize: 15,
                };
            }).filter(item => item.amount > 0);

            setChartData(data);

            const totalAmount = bills.reduce((sum, bill) => sum + bill.amount, 0);
            setTotalAmount(totalAmount);

            const totalQuantity = bills.length;
            setTotalQuantity(totalQuantity);
        }
    }, [bills, categories]);

    const dateRanges = DATE_RANGE_FILTERS;

    const handleCreateNew = () => {
        dispatch(billActions.resetBill());
        navigation.navigate(NEW_BILL);
    };

    const handleView = (bill) => {
        dispatch(billActions.setBill(bill));
        navigation.navigate(VIEW_BILL);
    };

    const handleEdit = (bill) => {
        dispatch(billActions.setBill(bill));
        navigation.navigate(NEW_BILL);
    };

    const handleDelete = (bill) => {
        updateBill({
            _id: bill._id,
            deleted: true
        });
    };

    const handleClearFilters = () => {
        setSelectedCategory(null);
        setSelectedDateRange(TODAY);
    };

    useEffect(() => {
        if (deleteBillResults.isSuccess) {
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Bill deleted successfully',
            });
            refetch();
        }
    }, [deleteBillResults.isLoading]);

    if (isLoading || isFetching || categoriesLoading || deleteBillResults.isLoading) {
        return <Loader message="Loading Bills..." />;
    }

    return (
        <View style={styles.container}>
            {CREATE_AND_FILTER.includes(employeeRoleCode) && (
                <RoundedButton title="Create New Bill" onPress={handleCreateNew} />
            )}

            <View style={styles.switchContainer}>
                <Text style={styles.switchLabel}>Show Filters & Chart</Text>
                <Switch
                    value={showFilters}
                    onValueChange={setShowFilters}
                />
            </View>

            {showFilters && (
                <>
                    <StayDropDown
                        data={categories ? categories.map(cat => ({ value: cat.name, key: cat._id })) : []}
                        placeholder="Filter By Category"
                        onSelectedItem={setSelectedCategory}
                        defaultOption={selectedCategory}
                    />

                    <StayDropDown
                        data={dateRanges.map(range => ({ value: range, key: range }))}
                        placeholder="Filter By Date Range"
                        onSelectedItem={setSelectedDateRange}
                        defaultOption={selectedDateRange}
                    />

                    <RoundedButton backgroundColor="lightgray" title="Clear Filters" onPress={handleClearFilters} />

                    <View style={styles.summaryContainer}>
                        <Text style={styles.summaryText}>Total Bill: {toCurrency(totalAmount)}</Text>
                        <Text style={styles.summaryText}>Quantity: {totalQuantity}</Text>
                    </View>

                    {chartData && chartData.length > 0 ? (
                        <PieChart
                            data={chartData}
                            width={Dimensions.get('window').width - 20}
                            height={220}
                            chartConfig={{
                                backgroundColor: '#ffffff',
                                backgroundGradientFrom: '#ffffff',
                                backgroundGradientTo: '#ffffff',
                                color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                            }}
                            accessor="amount"
                            backgroundColor="transparent"
                            paddingLeft="15"
                            absolute
                        />
                    ) : (
                        <Text>No data available for the chart</Text>
                    )}
                </>
            )}



            <FlatList
                data={bills || []}
                refreshing={isFetching}
                onRefresh={refetch}
                keyExtractor={item => item._id.toString()}
                renderItem={({ item }) => (
                    <BillItem
                        bill={item}
                        onView={() => handleView(item)}
                        onEdit={() => handleEdit(item)}
                        onDelete={() => handleDelete(item)}
                    />
                )}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
    },
    summaryContainer: {
        marginVertical: 10,
    },
    summaryText: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    switchContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 10,
    },
    switchLabel: {
        fontSize: 16,
    },
});

export default Bills;
