/**
 * OxDateRangePicker.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

// OxDateRangePicker.js

import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import moment from 'moment';
import colors from '../../strings/colors'; // Adjust the path as necessary

const screenWidth = Dimensions.get('window').width;

const OxDateRangePicker = ({
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    startLabel = 'Start Date',
    endLabel = 'End Date',
    minDate,
    maxDate,
}) => {
    const [isStartPickerVisible, setStartPickerVisibility] = useState(false);
    const [isEndPickerVisible, setEndPickerVisibility] = useState(false);

    const showStartPicker = () => {
        setStartPickerVisibility(true);
    };

    const hideStartPicker = () => {
        setStartPickerVisibility(false);
    };

    const handleConfirmStart = (date) => {
        hideStartPicker();
        onStartDateChange(date);
    };

    const showEndPicker = () => {
        setEndPickerVisibility(true);
    };

    const hideEndPicker = () => {
        setEndPickerVisibility(false);
    };

    const handleConfirmEnd = (date) => {
        hideEndPicker();
        onEndDateChange(date);
    };

    return (
        <View style={styles.container}>
            {/* Start Date Picker */}
            <Text style={styles.label}>{startLabel}</Text>
            <TouchableOpacity onPress={showStartPicker} style={styles.datePickerButton}>
                <Text style={styles.dateText}>
                    {startDate ? moment(startDate).format('YYYY-MM-DD HH:mm') : 'Select Start Date'}
                </Text>
            </TouchableOpacity>
            <DateTimePickerModal
                isVisible={isStartPickerVisible}
                mode="datetime"
                onConfirm={handleConfirmStart}
                onCancel={hideStartPicker}
                maximumDate={endDate ? new Date(endDate) : maxDate}
                minimumDate={minDate}
            />

            {/* End Date Picker */}
            <Text style={styles.label}>{endLabel}</Text>
            <TouchableOpacity onPress={showEndPicker} style={styles.datePickerButton}>
                <Text style={styles.dateText}>
                    {endDate ? moment(endDate).format('YYYY-MM-DD HH:mm') : 'Select End Date'}
                </Text>
            </TouchableOpacity>
            <DateTimePickerModal
                isVisible={isEndPickerVisible}
                mode="datetime"
                onConfirm={handleConfirmEnd}
                onCancel={hideEndPicker}
                minimumDate={startDate ? new Date(startDate) : minDate}
                maximumDate={maxDate}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        marginHorizontal: 5,
        marginBottom: 15,
    },
    label: {
        fontFamily: 'Poppins',
        fontSize: screenWidth * 0.04,
        marginBottom: 5,
        color: colors.light_gray2,
    },
    datePickerButton: {
        backgroundColor: 'lightgray',
        paddingVertical: 12,
        paddingHorizontal: 20,
        borderRadius: 25,
        marginBottom: 10,
    },
    dateText: {
        fontSize: screenWidth * 0.045,
        color: colors.black,
        fontFamily: 'Poppins-Bold',
        textAlign: 'center',
    },
});

export default OxDateRangePicker;
