import React, { useState } from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Modal, Linking, Platform } from 'react-native';
import MenuItem from './MenuItem';
import { useNavigation } from '@react-navigation/core';
import { useDispatch , useSelector } from 'react-redux';
import { userActions } from '../../redux/userSlice';
import colors from '../../strings/colors';
import Toast from 'react-native-toast-message';
import { ACCOUNTS, BILLS, CASHUPS, CHANGE_PASSWORD, COMPANIES, DEPOSITS, LOGIN, PROFILE, PURCHASING } from '../../config/constants/nav-routes.const';
import { ADMIN_MENU_ITEMS, CASHIER_MENU_ITEMS, SUPERVISOR_MENU_ITEMS } from '../../config/constants/menu-items.const';
import { ADMIN, CASHIER, SUPERVISOR } from '../../config/constants/retail-role-codes.const';


const MenuPopup = ({ visible, close }) => {

  const navigation = useNavigation();

  const dispatch = useDispatch();

  const employee = useSelector(state => state.employee);

  const getMenuItems = () => {
    switch (employee.role.code) {
      case ADMIN:
        return ADMIN_MENU_ITEMS;
      case SUPERVISOR:
        return SUPERVISOR_MENU_ITEMS;
      case CASHIER:
        return CASHIER_MENU_ITEMS;
      default:
        return CASHIER_MENU_ITEMS;
    }
  }

  const [menuItems, setMenuItems] = useState(getMenuItems());

  //handle item click

  const handleItemClick = (label) => {
    switch (label) {
      case 'Accounts':
        navigation.navigate(ACCOUNTS);
        close();
        break;
      case 'Profile':
        navigation.navigate(PROFILE);
        close();
        break;
      case 'Change Password':
        navigation.navigate(CHANGE_PASSWORD);
        close();
        break;
      case 'Invite & Earn':
        const affliaiteUrl = 'https://affiliate.siskus.com';
        if (Platform.OS === 'web') {
          Linking.openURL(affliaiteUrl);
        } else {
          navigation.navigate('MyWebView', { url: affliaiteUrl });
        }

        close();
        break;
      case 'Swith Companies':
        navigation.navigate(COMPANIES);
        close();
        break;

      case 'Cashups':
        navigation.navigate(CASHUPS);

        close();

        break;

      case 'Bills':
        navigation.navigate(BILLS);

        close();
        break;

      case 'Deposits':
        navigation.navigate(DEPOSITS);

        close();

        break;
      case 'Purchasing':
        navigation.navigate(PURCHASING);
        close();

        break;
      case 'Logout':
        dispatch(userActions.removeUser());
        navigation.navigate(LOGIN);
        close();
        break;
    }
  };

  return (
    <Modal
      animationType='slide'
      transparent={true}
      visible={visible}
      onRequestClose={close}
    >
      <TouchableOpacity style={styles.background} onPress={close}>
        <View style={styles.menu} onPress={close}>
          <View style={styles.menuItemsContainer}>
            {menuItems.map((item, index) => (
              <MenuItem key={index} icon={item.icon} label={item.label} onPress={() => handleItemClick(item.label)} />
            ))}
          </View>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menu: {
    backgroundColor: colors.white,
    margin: 20,
    borderRadius: 20,
    padding: 20,
    alignItems: 'center',

  },

  menuItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default MenuPopup;
