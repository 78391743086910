// CashupForm.js

import React, { useEffect, useState } from 'react';
import {
    ScrollView,
    View,
    Text,
    StyleSheet,
    Dimensions,
    TouchableOpacity,
    ActivityIndicator,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import RoundedInput from '../../components/common/RoundedInput';
import OxDateRangePicker from '../../components/common/OxDateRangePicker'; // Import the new component
import moment from 'moment';
import colors from '../../strings/colors';
import {
    useGetCompanyEmployeesQuery, 
    useGetStockLocationsQuery,
} from '../../redux/api';
import { cashupActions } from '../../redux/cashupSlices';
import StayDropDown from '../../components/common/StayDropDown';
import { EXPO_PUBLIC_OSPOS_ENDPOINT } from '../../config/app.conf';

const screenWidth = Dimensions.get('window').width;

const CashupForm = () => {
    const dispatch = useDispatch();
    const cashup = useSelector((state) => state.cashup);
    const company = useSelector((state) => state.company);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [data, setData] = useState(null);

    const { data: employees, isLoading: employeesLoading } = useGetCompanyEmployeesQuery(company._id);
    const { data: stockLocations, isLoading: stockLocationsLoading } = useGetStockLocationsQuery(company._id);

    const fetchData = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch(`${EXPO_PUBLIC_OSPOS_ENDPOINT}/report`);
            if (!response.ok) {
                throw new Error('Failed to fetch data from server');
            }
            const jsonData = await response.json();
            setData(jsonData);
        } catch (err) {
            setError('Failed to load data: ' + err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleStockLocationChange = (location) => {
        if (!data || !data.dailyRevenue) {
            console.error('Daily revenue data is not available');
            return;
        }
        const locationRevenueMap = data.dailyRevenue.reduce((acc, revenueData, index) => {
            const locationCode = `no${index + 1}`;
            acc[locationCode] = revenueData.daily_revenue;
            return acc;
        }, {});

        const locationDailySale = locationRevenueMap[location.location_code];

        dispatch(cashupActions.setCashupPeriodCashSale(locationDailySale));
        dispatch(cashupActions.setCashupLocation(location._id));
        dispatch(cashupActions.setCashupSaleAccount(location.sale_account));
        dispatch(cashupActions.setCashupCashAccount(location.cash_account));
    };

    // Handlers for Date Range Picker
    const handleStartDateChange = (date) => {
        dispatch(cashupActions.setCashupOpenningTime(moment(date).toISOString()));
    };

    const handleEndDateChange = (date) => {
        dispatch(cashupActions.setCashupClosingTime(moment(date).toISOString()));
    };

    // Formatting Dates for Display
    const formattedOpeningDate = cashup.cash_up_openning_time
        ? moment(cashup.cash_up_openning_time).format('YYYY-MM-DD HH:mm')
        : new Date().setHours(0, 0, 0, 0);

    const formattedClosingDate = cashup.cash_up_closing_time
        ? moment(cashup.cash_up_closing_time).format('YYYY-MM-DD HH:mm')
        : new Date().setHours(23, 59, 59, 999);

    useEffect(() => {
         if(cashup.cash_up_openning_time===''){
            dispatch(cashupActions.setCashupOpenningTime(formattedOpeningDate));
        }
        if(cashup.cash_up_closing_time===''){
            dispatch(cashupActions.setCashupClosingTime(formattedClosingDate));
        }
    }, [ cashup.cash_up_openning_time, cashup.cash_up_closing_time]);

    // Define the toPascalCase function
    const toPascalCase = (s) => {
        return s
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join('');
    };

    return (
        <ScrollView style={styles.container}>
            <View style={styles.layout}>
                {/* Reload Button */}
                <TouchableOpacity
                    onPress={fetchData}
                    style={[
                        styles.reloadButton,
                        loading && styles.disabledButton,
                    ]}
                    disabled={loading}
                >
                    <Text style={styles.reloadButtonText}>Reload Data</Text>
                    {loading && (
                        <ActivityIndicator color="white" style={{ marginLeft: 10 }} />
                    )}
                </TouchableOpacity>

                {/* Display Error if any */}
                {error ? <Text style={styles.errorText}>{error}</Text> : null}

                {/* Stock Location */}
                <Text style={styles.inputLabel}>Stock Location</Text>
                <StayDropDown
                    data={
                        stockLocations
                            ? stockLocations.map((account) => ({
                                  value: account.name,
                                  key: account,
                              }))
                            : []
                    }
                    placeholder="Select Stock Location"
                    onSelectedItem={handleStockLocationChange}
                    defaultOption={cashup.location}
                />

                {/* Cashier */}
                <Text style={styles.inputLabel}>Cashier</Text>
                <StayDropDown
                    data={
                        employees
                            ? employees.map((employee) => ({
                                  value: employee.first_name,
                                  key: employee._id,
                              }))
                            : []
                    }
                    placeholder="Select Cashier"
                    onSelectedItem={(cashier) =>
                        dispatch(cashupActions.setCashupCashier(cashier))
                    }
                    defaultOption={cashup.cashier}
                />

                {/* OxDateRangePicker for Cashup Opening and Closing Time */}
                <OxDateRangePicker
                    startDate={cashup.cash_up_openning_time ? new Date(cashup.cash_up_openning_time) : formattedOpeningDate}
                    endDate={cashup.cash_up_closing_time ? new Date(cashup.cash_up_closing_time) : formattedClosingDate}
                    onStartDateChange={handleStartDateChange}
                    onEndDateChange={handleEndDateChange}
                    startLabel="Cashup Opening Time"
                    endLabel="Cashup Closing Time"
                    minDate={null} // You can set a minimum date if needed
                    maxDate={null} // You can set a maximum date if needed
                />

                {/* Cash Sale Total */}
                <Text style={styles.inputLabel}>Cash Sale Total</Text>
                <RoundedInput
                    placeholder="Enter Cash Sale Total"
                    value={cashup.period_cash_sale?.toString() || ''}
                    keyboardType="numeric"
                    disabled={true}
                    onChangeText={(text) =>
                        dispatch(cashupActions.setCashupPeriodCashSale(text))
                    }
                />

                {/* Kazang Sale Total */}
                <Text style={styles.inputLabel}>Kazang Sale Total</Text>
                <RoundedInput
                    placeholder="Enter Kazang Sale Total"
                    value={cashup.kazang_sale?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) =>
                        dispatch(cashupActions.setCashupKazangSale(text))
                    }
                />

                {/* Cash In */}
                <Text style={styles.inputLabel}>Cash In</Text>
                <RoundedInput
                    placeholder="Enter Cash In Amount"
                    value={cashup.cash_in?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) =>
                        dispatch(cashupActions.setCashupCashIn(text))
                    }
                />

                {/* Cash In Reason */}
                {Number(cashup.cash_in) !== 0 && (
                    <>
                        <Text style={styles.inputLabel}>Cash In Reason</Text>
                        <RoundedInput
                            placeholder="Enter Cash In Reason"
                            value={cashup.cash_in_reason || ''}
                            onChangeText={(text) =>
                                dispatch(
                                    cashupActions.setCashupCashInReason(text)
                                )
                            }
                        />
                    </>
                )}

                {/* Cash Out */}
                <Text style={styles.inputLabel}>Cash Out</Text>
                <RoundedInput
                    placeholder="Enter Cash Out Amount"
                    value={cashup.cash_out?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) =>
                        dispatch(cashupActions.setCashupCashOut(text))
                    }
                />

                {/* Cash Out Reason */}
                {Number(cashup.cash_out) !== 0 && (
                    <>
                        <Text style={styles.inputLabel}>Cash Out Reason</Text>
                        <RoundedInput
                            placeholder="Enter Cash Out Reason"
                            value={cashup.cash_out_reason || ''}
                            onChangeText={(text) =>
                                dispatch(
                                    cashupActions.setCashupCashOutReason(text)
                                )
                            }
                        />
                    </>
                )}

                {/* Notes and Coins */}
                {[
                    { label: 'Notes 200', key: 'notes_200' },
                    { label: 'Notes 100', key: 'notes_100' },
                    { label: 'Notes 50', key: 'notes_50' },
                    { label: 'Notes 30', key: 'notes_30' },
                    { label: 'Notes 20', key: 'notes_20' },
                    { label: 'Notes 10', key: 'notes_10' },
                    { label: 'Coins 5', key: 'coins_5' },
                    { label: 'Coins 1', key: 'coins_1' },
                    { label: 'Coins 50c', key: 'coins_50c' },
                    { label: 'Coins 10c', key: 'coins_10c' },
                    { label: 'Coins 5c', key: 'coins_5c' },
                ].map((item) => (
                    <View key={item.key} style={styles.inputGroup}>
                        <Text style={styles.inputLabel}>{item.label}</Text>
                        <RoundedInput
                            placeholder={`Enter ${item.label}`}
                            value={
                                cashup[item.key]?.toString() || ''
                            }
                            keyboardType="numeric"
                            onChangeText={(text) =>
                                dispatch(cashupActions[`setCashup${toPascalCase(item.key)}`](text))
                            }
                        />
                    </View>
                ))}

                {/* You can add a submit button or any other components here */}
            </View>
        </ScrollView>
    );
};

// Helper function to convert snake_case to PascalCase
const toPascalCase = (s) => {
    return s
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join('');
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: colors.white,
    },
    layout: {
        flexDirection: 'column',
    },
    inputLabel: {
        fontFamily: 'Poppins',
        fontSize: screenWidth * 0.04,
        marginBottom: 5,
        marginLeft: 20,
        color: colors.light_gray2,
    },
    datePickerButton: {
        backgroundColor: colors.light_gray,
        paddingVertical: 12,
        paddingHorizontal: 20,
        borderRadius: 8,
        marginHorizontal: 20,
        marginBottom: 15,
    },
    dateInput: {
        fontSize: screenWidth * 0.045,
        color: colors.black,
        textAlign: 'center',
    },
    reloadButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
        padding: 12,
        backgroundColor: colors.stay_blue,
        borderRadius: 8,
    },
    reloadButtonText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 16,
    },
    disabledButton: {
        opacity: 0.5,
    },
    errorText: {
        color: 'red',
        textAlign: 'center',
        marginBottom: 10,
    },
    inputGroup: {
        marginBottom: 15,
        marginHorizontal: 20,
    },
});

export default CashupForm;
