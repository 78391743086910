/**
 * GeneratePO.jsx
 *
 * @author Immanuel N Fransiskus
 * @copyright 2024
 * All rights reserved
 */

import React, { useState } from 'react';
import RoundedInput from '../../components/common/RoundedInput';
import RoundedButton from '../../components/common/RoundedButton';
import { EXPO_PUBLIC_OSPOS_ENDPOINT } from '../../config/app.conf';
import { FlatList, ScrollView, Text, StyleSheet, View, ActivityIndicator } from 'react-native';
import { useGetStockLocationsQuery } from '../../redux/api';
import Loader from '../../components/common/Loader';
import StayDropDown from '../../components/common/StayDropDown';
import { useSelector } from 'react-redux';

function GeneratePO() {

  const company = useSelector(state => state.company);

  const [location_id, setLocationId] = useState(1);
  const [supplier_id, setSupplierId] = useState(15);
  const [quantity, setQuantity] = useState(50);
  const [isLoading, setIsLoading] = useState(false);
  const [po_data, setPOData] = useState([]);

  const [loadingMessage,setLoadingMessage] = useState('Loading...');

  const [location_suppliers, setLocationSuppliers] = useState([]);

  const { data: stockLocations, isLoading: stockLocationsLoading } = useGetStockLocationsQuery(company._id);

  const handleGeneratePO = async () => {
    console.log(location_id, supplier_id, quantity);
    setIsLoading(true);
    setLoadingMessage("Generating PO...")
    try {
      const fetch_po_data_response = await fetch(`${EXPO_PUBLIC_OSPOS_ENDPOINT}/generate-po/${location_id}/${supplier_id}/${quantity}`);
      const po_data = await fetch_po_data_response.json();
      setPOData(po_data[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getLocationSuppiers = async (location_id) => {
    setIsLoading(true);
    setLoadingMessage('Loading Suppliers...')
    try {

      const fetch_location_suppliers_response = await fetch(`${EXPO_PUBLIC_OSPOS_ENDPOINT}/location-suppliers/${location_id}`);
      const location_suppliers = await fetch_location_suppliers_response.json();
      setLocationSuppliers(location_suppliers);

    } catch (error) {
      console.log(error);
    }finally{
      setIsLoading(false)
      setLoadingMessage('Loading...')
    }
  }

  const handleStockLocationChange = async (location) => {
    const location_id = location.location_code.charAt(2);
    setLocationId(location_id);
    await getLocationSuppiers(location_id);
  };

  if (isLoading || stockLocationsLoading) {
    return <Loader message={loadingMessage}/>
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Text style={styles.title}>Generate PO</Text>

      <StayDropDown
        data={stockLocations ? stockLocations.map(account => ({ value: account.name, key: account })) : []}
        placeholder="Select Stock Location"
        onSelectedItem={handleStockLocationChange}
        defaultOption={location_id}
      />

       {location_suppliers.length>0 && <>
       <StayDropDown
        data={location_suppliers ? location_suppliers.map(supplier => ({ value: supplier.company_name, key: supplier })) : []}
        placeholder="Select Supplier"
        onSelectedItem={(supplier)=>setSupplierId(supplier.person_id)}
        defaultOption={supplier_id}
        />

      <RoundedInput
        value={quantity.toString()}
        placeholder="Enter Quantity"
        onChangeText={(text) => setQuantity(text)}
        style={styles.input}
      />

      <RoundedButton title={"Generate PO"} onPress={handleGeneratePO} style={styles.button} />
      </>}

      {/** Display PO Data */}
      <FlatList
        data={po_data}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => (
          <View style={styles.itemContainer}>
            <Text style={styles.itemText}>{item.name}</Text>
          </View>
        )}
        contentContainerStyle={styles.listContainer}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 16,
    backgroundColor: '#f8f9fa',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    textAlign: 'center',
    color: '#343a40',
  },
  input: {
    marginBottom: 12,
  },
  button: {
    marginVertical: 12,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f8f9fa',
  },
  loadingText: {
    marginTop: 10,
    fontSize: 18,
    color: '#343a40',
  },
  listContainer: {
    marginTop: 16,
  },
  itemContainer: {
    padding: 10,
    backgroundColor: '#ffffff',
    borderBottomWidth: 1,
    borderBottomColor: '#dee2e6',
  },
  itemText: {
    fontSize: 16,
    color: '#495057',
  },
});

export default GeneratePO;
