/**
 * app.conf.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const API_ENDPOINT='https://oxygen.siskusserver.com/api'
//const API_ENDPOINT='https://staging.oxygen.siskusserver.com/api'

const APP_ENCRYPTION_KEY='0xYg3n@2024'
const APP_FRONTEND_URL='https://mobile.oxygen-erp.com'
const APP_MODE='OXYGEN'
const APP_ENV='production'
const OSPOS_ENDPOINT= 'https://ospos.siskusserver.com'

//no image uri
const NO_IMAGE_URI='https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'

 const   EXPO_PUBLIC_API_ENDPOINT = process.env.EXPO_PUBLIC_API_ENDPOINT || API_ENDPOINT;
 const  EXPO_PUBLIC_APP_ENCRYPTION_KEY = process.env.EXPO_PUBLIC_APP_ENCRYPTION_KEY || APP_ENCRYPTION_KEY;
 const  EXPO_PUBLIC_APP_FRONTEND_URL = process.env.EXPO_PUBLIC_APP_FRONTEND_URL || APP_FRONTEND_URL;
 const  EXPO_PUBLIC_APP_MODE = process.env.EXPO_PUBLIC_APP_MODE || APP_MODE;
 const  EXPO_PUBLIC_APP_ENV = process.env.EXPO_PUBLIC_APP_ENV || APP_ENV;
 const  EXPO_PUBLIC_OSPOS_ENDPOINT = process.env.EXPO_PUBLIC_OSPOS_ENDPOINT || OSPOS_ENDPOINT;

export {
    EXPO_PUBLIC_API_ENDPOINT,
    EXPO_PUBLIC_APP_ENCRYPTION_KEY,
    EXPO_PUBLIC_APP_FRONTEND_URL,
    EXPO_PUBLIC_APP_MODE,
    EXPO_PUBLIC_APP_ENV,
    NO_IMAGE_URI,
    EXPO_PUBLIC_OSPOS_ENDPOINT
}