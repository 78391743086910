import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, ActivityIndicator } from 'react-native';
import RoundedInput from '../../components/common/RoundedInput';
import RoundedButton from '../../components/common/RoundedButton';
import colors from '../../strings/colors';
import { useNavigation } from '@react-navigation/core';
import { useDeleteListingImageMutation, useUpdateProfileMutation } from '../../redux/api';
import Loader from '../../components/common/Loader';
import { useSelector, useDispatch } from 'react-redux';
import Toast from 'react-native-toast-message';
import * as ImagePicker from 'expo-image-picker';
import { userActions } from '../../redux/userSlice';
import { EXPO_PUBLIC_API_ENDPOINT } from '../../config/app.conf';
import * as ImageManipulator from 'expo-image-manipulator';
import ProfilePic from '../../components/common/ProfilePic';

const Profile = () => {

  const FILENAME_PREFIX = 'profile.jpg';

  const user_state = useSelector(state => state.user);
  const dispatch = useDispatch();

  const profile_url = `${EXPO_PUBLIC_API_ENDPOINT}/stream/profile.jpg-${user_state._id}`;

  const [name, setName] = useState(user_state.username || '');

  const [cellphone, setCellphone] = useState(user_state.cellphone || '');

  const [email, setEmail] = useState(user_state.email || '');



  const [status, setStatus] = useState('');

  const [updateProfile, updateProfileResult] = useUpdateProfileMutation();

  const [deleteImage, deleteImageResult] = useDeleteListingImageMutation();

  const navigation = useNavigation();



  const uploadProfilePicture = async (uri) => {
    try {
      

      setUploading(true);

      const deleted = await deleteImage(`${FILENAME_PREFIX}-${user_state._id}`).unwrap();

      // Resize and Compress Image
      const resizedImage = await ImageManipulator.manipulateAsync(
        uri,
        [{ resize: { width: 150 } }], // Resize to a width of 800 pixels (or your desired size)
        { compress: 1, format: ImageManipulator.SaveFormat.JPEG } // Compress to 70% quality
      );

      const formData = new FormData();

      formData.append('file', {
        uri: resizedImage.uri,
        type: 'image/jpeg',
        name: `${FILENAME_PREFIX}`, // Ensure the file name is unique and correctly formatted
      });

      formData.append('_id', user_state._id);

      await uploadImage(formData, user_state._id);

      Toast.show({
        type: 'success',
        text1: 'Success',
        text2: 'Profile picture uploaded successfully'
      });

      

    } catch (error) {
      console.log(error);
      Toast.show({
        type: 'error',
        text1: 'Error',
        text2: error.message || 'An error occurred',
      });
    } finally {
      setUploading(false);
    }
  };


  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.canceled) {
      setProfileImage(result.assets[0].uri);
      await uploadProfilePicture(result.assets[0].uri);
    }
  };

  const handleNameChange = text => {
    setName(text);
  };

  const handleCellphoneChange = text => {
    setCellphone(text);
  };

  const handleUpdate = () => {
    if (name === '') {
      Toast.show({
        type: 'error',
        text1: 'Validation Error',
        text2: 'Please enter your name'
      });
      return;
    }

    if (cellphone === '') {
      Toast.show({
        type: 'error',
        text1: 'Validation Error',
        text2: 'Please enter your cellphone'
      });
      return;
    }

    updateProfile({
      _id: user_state._id,
      name: name,
      email: user_state.email,
      cellphone: cellphone,
    });
  };

  useEffect(() => {
    if (updateProfileResult.isSuccess) {
      dispatch(userActions.setUser(updateProfileResult.data));
      Toast.show({
        type: 'success',
        text1: 'Success',
        text2: 'Profile updated successfully'
      });
    }
    if (updateProfileResult.isError) {
      Toast.show({
        type: 'error',
        text1: 'Error',
        text2: updateProfileResult.error.message
      });
      console.log(updateProfileResult.error);
    }
  }, [updateProfileResult.isLoading]);

  useEffect(() => {
    if (!user_state._id) {
        // Redirect to login
        navigation.navigate('Login');
    }
}, [user_state]);

  if (updateProfileResult.isLoading) {
    return (
      <Loader />
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.layout}>
        <TouchableOpacity onPress={pickImage} style={styles.profileImageContainer}>
         <ProfilePic url={profile_url} username={user_state?.username||"User"} width={100}  />
        </TouchableOpacity>
        <RoundedInput placeholder="Your Name" value={name} onChangeText={handleNameChange} />

        <RoundedInput placeholder="Your Cellphone" keyboardType='phone-pad' value={cellphone} onChangeText={handleCellphoneChange} />

        <RoundedInput placeholder="Your Email" keyboardType='email-address' value={email} onChangeText={setEmail} />

        <Text style={styles.statusText}>{status}</Text>
        <RoundedButton title="UPDATE PROFILE" onPress={handleUpdate} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  layout: {
    width: '90%',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
  },
  statusText: {
    color: colors.orange,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  profileImageContainer: {
    alignSelf: 'center',
    marginBottom: 20,
  },
  cameraIcon: {
    position: 'absolute',
    right: 6,
    bottom: 6,
    backgroundColor: colors.stay_blue,
    borderRadius: 30,
    padding: 5,
  },
});

export default Profile;
