import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { SelectList } from 'react-native-dropdown-select-list';
import colors from '../../strings/colors';

const StayDropDown = ({data,placeholder,onSelectedItem, defaultOption}) => {

    return (
            <SelectList
                setSelected={selectedItem => onSelectedItem(selectedItem)}
                data={data}
                placeholder={placeholder}
                inputStyles={{fontFamily: 'Poppins', color: colors.black, fontSize: 16, borderRadius: 25, borderColor: 'transparent', borderWidth: 0}}
                boxStyles={styles.dropdown}
                dropdownTextStyles={styles.dropdownText}
                defaultOption= {defaultOption}
            />
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    filterLabel: {
        fontSize: 16,
        marginBottom: 10,
        color: colors.black,
        fontFamily: 'Poppins-Bold'
    },
    dropdown: {
        borderWidth: 1,
        borderColor: '#eee',
        backgroundColor: '#eee',
        width: "100%",
        borderRadius: 20,
        paddingVertical: 8,
        paddingHorizontal: 10,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 25,
        backgroundColor: 'lightgray',
        width: '100%',
        borderColor: '#333',
        marginBottom: 15,
    
    },
    dropdownText: {
        color: colors.black,
        fontFamily: 'Poppins',
        borderRadius: 25,
        
        flex: 1,
        paddingVertical: 12, // Fixed vertical padding
        
        borderColor: 'transparent',
        borderWidth: 0,
    },
    // Additional styles if needed
});

export default StayDropDown;
